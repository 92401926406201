<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-row class="">
      <v-col v-for="(article, idx) in articles" :key="idx" cols="12" :sm="idx == 0 ? 12 : 6" :md="idx == 0 ? 12 : 4">
        <v-card :to="{name: 'blogPost', params: { id: article.slug }}">
          <v-img v-if="article.thumbnailUrl"
            :src="article.thumbnailUrl"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
            height="300px"
          >
            <v-card-title class="display-1" v-text="article.title"></v-card-title>
          </v-img>
          <v-card-title v-else class="display-2" v-text="article.title"></v-card-title>

          <v-card-text v-if="article.description">
            <span class="black--text">{{ article.created }} • </span>{{ article.description }}
          </v-card-text>
          <v-card-text v-if="idx==0">
            <p><span class="black--text">{{ article.created }} • </span>{{ article.summary }}</p>
            <v-btn color="primary" outlined>Read full post</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Blog",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      articles: null,
    };
  },
  created() {
  },
  async mounted() {
    this.articles = await articleService.listByCategory('blog');

    this.$nextTick(() => {
      // when content is loaded
    });

  },
  methods: {
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Blog', exact: true, to: { name: 'blog' }},
      ];
    },
  },
};
</script>

<style scoped>
</style>